import React from 'react';
import TeamIcon from '@untitled-ui/icons-react/build/esm/Users01';
import {IconButton, SvgIcon, Tooltip} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {TeamPopover} from './team-popover';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export const TeamButton = () => {
    const popover = usePopover();
    const { t } = useTranslation();
    const context = useSelector(state => state.service.user.context);

    if(!context?.is_company_context)
        return null;

    return (
        <>
            <Tooltip enterDelay={1000} title={t("layout.team")}>
                <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
                    <SvgIcon>
                        <TeamIcon/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <TeamPopover
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                open={popover.open}
            />
        </>
    );
};
