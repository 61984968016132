import { useFormik } from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import ListIcon from "@untitled-ui/icons-react/build/esm/Dotpoints02";
import FlowIcon from "@untitled-ui/icons-react/build/esm/Dataflow03";
import FormIcon from "@untitled-ui/icons-react/build/esm/TextInput";
import SurveyIcon from "@untitled-ui/icons-react/build/esm/Announcement01";
import EventIcon from "@untitled-ui/icons-react/build/esm/CalendarHeart02";
import {APP_SETTING} from "../../../setup";
import ServiceIcon from "@untitled-ui/icons-react/build/esm/Divider";
import OnIcon from "../../components/elements/icon";
import React from "react";

const useCrmFormik = (initialData, onSubmit) => {

    const { t } = useTranslation();

    const NOTE_TYPES = ['call', 'seminar', 'email', 'meeting', 'online', 'event', 'system', 'other'];
    const ENTITY_TYPES = ['organization', 'person'];
    const CONTACT_TYPES = ['customer', 'supplier', 'partner', 'contact', 'other'];
    const LEAD_PIPELINE_STATES = ['new', 'contacted', 'qualified', 'unqualified', 'converted', 'lost'];
    const TASK_STATUS = ['open', 'in_progress', 'done', 'on_hold', 'canceled'];
    const TASK_TYPES = ['incoming_call', 'outgoing_call', 'incoming_email', 'outgoing_email', 'meeting',
        'send_documents', 'no_interest', 'callback', 'other'];
    const TASK_PRIORITIES = ['low', 'normal', 'high', 'urgent'];
    const ACCOUNT_TYPES = ['linkedin', 'xing', 'facebook', 'twitter', 'instagram', 'pintrest', 'tiktok',
        'youtube', 'other'];
    const CONTEXT_KINDS = [
        {
            value: 'list',
            disabled: false,
            label: t('connections.context.list'),
            description: t('connections.context.list_description'),
            icon: ListIcon
        },
        {
            value: 'form',
            disabled: true,
            label: t('connections.context.form'),
            description: t('connections.context.form_description'),
            icon: FormIcon
        },
        {
            value: 'event',
            disabled: true,
            label: t('connections.context.event'),
            description: t('connections.context.event_description'),
            icon: EventIcon
        },
        {
            value: 'survey',
            disabled: true,
            label: t('connections.context.survey'),
            description: t('connections.context.survey_description'),
            icon: SurveyIcon
        },
        {
            value: 'flow',
            disabled: true,
            label: t('connections.context.flow'),
            description: t('connections.context.flow_description'),
            icon: FlowIcon
        },
        {
            value: 'service',
            disabled: (APP_SETTING?.services?.length || 0) === 0,
            label: t('connections.context.service'),
            description: t('connections.context.service_description'),
            icon: ServiceIcon
        }
    ]
    const CONTEXT_LAYOUTS = ['vertical', 'horizontal', 'plain'];
    const CONTEXT_PALETTE_MODES = ['light', 'dark'];
    const CONTEXT_NAV_COLORS = ['blend-in', 'discreet', 'evident'];
    const CONTEXT_ACCESS = ['open_access', 'invite_only'];
    const CONTEXT_RESPONSIBILITY_ASSIGNMENT = ['off', 'random', 'equal'];
    const CONTEXT_REQUIRED_FIELDS = ['mobile', 'username', 'email', 'first_name', 'last_name', 'password'];

    const eventIcons = {

        // space-events created by the users
        'call': <OnIcon iconName="Airpods" size="small" />,
        'seminar': <OnIcon iconName="AnnotationInfo" size="small" />,
        'meeting': <OnIcon iconName="Users01" size="small" />,
        'event': <OnIcon iconName="CalendarCheck01" size="small" />,
        'other': <OnIcon iconName="Bell01" size="small" />,

        // Put jobs a little extra
        'task': <OnIcon iconName="CheckCircle" size="small" />,

        // Default system mode (no icon)
        'system': null,

        // Email related
        'email': <OnIcon iconName="Mail01" size="small" />,
        'planned-email': <OnIcon iconName="Clock" size="small" />,

        // Click related
        'click': <OnIcon iconName="Link01" size="small" />,

        // CMS related
        'online': <OnIcon iconName="Globe04" size="small" />,
    }

    const RESPONSIBILITY_ASSIGNMENT_HELPERS = {
        'off': t('connections.context.responsibility_assignment_off'),
        'random': t('connections.context.responsibility_assignment_random'),
        'equal': t('connections.context.responsibility_assignment_equal')
    }

    const contactTaskFormik = useFormik({
        initialValues: initialData ? initialData : {
            title: '',
            body: '',
            status: 'open',
            task_type: 'other',
            priority: 'normal',
            assigned_to: [],
            contact: null
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().max(200, t('formik.max_length', {characters: 200})).required(t('formik.max_length', {field: t('attributes.title')})),
            body: Yup.string(),
            status: Yup.string().max(20, t('formik.max_length', {characters: 20}))
                .oneOf(TASK_STATUS, t('form.invalid_selection', {field: t('attributes.task_status')})),
            task_type: Yup.string().max(20, t('formik.max_length', {characters: 20}))
                .oneOf(TASK_TYPES, t('form.invalid_selection', {field: t('attributes.task_type')})),
            priority: Yup.string().max(20, t('formik.max_length', {characters: 20}))
                .oneOf(TASK_PRIORITIES, t('form.invalid_selection', {field: t('attributes.task_priority')})),
            assigned_to: Yup.array().of(Yup.number()),
            contact: Yup.number().required(t('form.is_required', {field: t('attributes.contact')})).nullable(),
        }),
        onSubmit: onSubmit,
    })

    const contextNotificationFormik = useFormik({
        initialValues: initialData ? initialData : {
            registration: null,
            title: '',
            icon: 'Bell01',
            body: '',
            link: null
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            registration: Yup.number().required(t('form.is_required', {field: t('attributes.user')})).nullable(),
            title: Yup.string().max(100, t('form.max_length', {characters: 100})).required(t('form.is_required', {field: t('attributes.title')})),
            icon: Yup.string().max(20, t('form.max_length', {characters: 20})),
            body: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.body')})),
            link: Yup.string().max(200, t('form.max_length', {characters: 200})).nullable()
        }),
        onSubmit: onSubmit,
    })

    const contextFormik = useFormik({
        enableReinitialize: true,
        initialValues: initialData ? initialData : {
            name: '',
            service_identifier: null,
            title: '',
            theme: null,
            subheader: '',
            register_button: t('common.register'),
            login_button: t('common.login'),
            kind: null,
            password_lifetime: 180,
            destination_contact_type: null,
            destination_lead_pipeline_state: null,
            use_multi_factor_authentication: false,
            is_company_context: false,
            register_redirect: '',
            double_opt_in: false,
            double_opt_in_email_body: t('connections.context.opt_in_email_html'),
            double_opt_in_email_subject: t('connections.context.opt_in_email_subject'),
            layout: 'plain',
            palette_mode: 'light',
            nav_color: 'blend-in',
            access_mode: 'open_access',
            responsibility_assignment_mode: 'off',
            responsible_users: [],
            users_to_notify_on_registration: [],
            required_fields: [],
            additional_fields: [],
            maximum_members: null,

            register_success_title: null,
            register_success_body: null,
            opt_in_title: null,
            opt_in_body: null,
            opt_in_cta: null,
            opt_in_success_title: null,
            opt_in_success_body: null,
            opt_out_title: null,
            opt_out_body: null,
            opt_out_cta: null,
            opt_out_success_title: null,
            opt_out_success_body: null
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .max(200, t('form.max_length', {characters: 200})),
            title: Yup.string()
                .max(100, t('form.max_length', {characters: 100})),
            register_button: Yup.string()
                .max(70, t('form.max_length', {characters: 70})),
            login_button: Yup.string()
                .max(70, t('form.max_length', {characters: 70})),
            subheader: Yup.string()
                .max(200, t('form.max_length', {characters: 200})),
            kind: Yup.string()
                .oneOf(CONTEXT_KINDS.map(k => k.value), t('form.invalid_selection', {field: t('attributes.kind')}))
                .required(t('form.is_required', {field: t('attributes.kind')})),
            theme: Yup.number().nullable(),
            password_lifetime: Yup.number()
                .min(0, t('form.min_value', {value: 0}))
                .max(365, t('form.max_value', {value: 365}))
                .required(t('form.is_required', {field: t('attributes.password_lifetime')})),
            use_multi_factor_authentication: Yup.bool(),
            is_company_context: Yup.bool(),
            register_redirect: Yup.string()
                .max(200, t('form.max_length', {characters: 200}))
                .nullable(),
            layout: Yup.string()
                .oneOf(CONTEXT_LAYOUTS, t('form.invalid_selection', {field: t('attributes.layout')}))
                .required(t('form.is_required', {field: t('attributes.layout')})),
            access_mode: Yup.string()
                .oneOf(CONTEXT_ACCESS, t('form.invalid_selection', {field: t('attributes.service_access_mode')}))
                .required(t('form.is_required', {field: t('attributes.service_access_mode')})),
            responsibility_assignment_mode: Yup.string()
                .oneOf(CONTEXT_RESPONSIBILITY_ASSIGNMENT, t('form.invalid_selection', {field: t('attributes.responsibility_assignment_mode')}))
                .required(t('form.is_required', {field: t('attributes.responsibility_assignment_mode')})),
            responsible_users: Yup.array()
                .of(Yup.number())
                .nullable(),
            users_to_notify_on_registration: Yup.array()
                .of(Yup.number())
                .nullable(),
            palette_mode: Yup.string()
                .oneOf(CONTEXT_PALETTE_MODES, t('form.invalid_selection', {field: t('attributes.palette_mode')}))
                .required(t('form.is_required', {field: t('attributes.palette_mode')})),
            nav_color: Yup.string()
                .oneOf(CONTEXT_NAV_COLORS, t('form.invalid_selection', {field: t('attributes.nav_color')}))
                .required(t('form.is_required', {field: t('attributes.nav_color')})),
            destination_contact_type: Yup.string()
                .oneOf(CONTACT_TYPES, t('form.invalid_selection', {field: t('attributes.destination_contact_type')}))
                .nullable()
                .required(t('form.is_required', {field: t('attributes.destination_contact_type')})),
            destination_lead_pipeline_state: Yup.string()
                .oneOf(LEAD_PIPELINE_STATES.concat([null]), t('form.invalid_selection', {field: t('attributes.lead_state')}))
                .nullable(),
            required_fields: Yup.array()
                .test('includes-password', t('form.must_have_password'), (value, vals) => {
                    if(vals.parent?.kind === 'service'){
                        if(!value.includes('password')){
                            return false;
                        }
                    }
                    return true;
                })
                .test('includes-auth-identity', t('form.must_have_email_co'), (value, vals) => {
                    if(vals.parent?.kind === 'service'){
                        if(value.includes('email') || value.includes('username') || value.includes('mobile')){
                            return true;
                        }
                        return false;
                    } else {
                        return true;
                    }

                })
                .test('includes-email', t('form.must_have_email'), (value, vals) => {
                    if(vals.parent?.kind !== 'service'){
                        return value.includes('email');
                    }
                    return true;
                }),
            additional_fields: Yup.array()
                .nullable(),
            maximum_members: Yup.number()
                .nullable(),
            register_success_title: Yup.string().nullable(),
            register_success_body: Yup.string().nullable(),
            opt_in_title: Yup.string().nullable(),
            opt_in_body: Yup.string().nullable(),
            opt_in_cta: Yup.string().nullable(),
            opt_in_success_title: Yup.string().nullable(),
            opt_in_success_body: Yup.string().nullable(),
            opt_out_title: Yup.string().nullable(),
            opt_out_body: Yup.string().nullable(),
            opt_out_cta: Yup.string().nullable(),
            opt_out_success_title: Yup.string().nullable(),
            opt_out_success_body: Yup.string().nullable(),
        }),
        onSubmit: onSubmit,
    });

    const contactFormik = useFormik({
        enableReinitialize: true,
        initialValues: initialData ? initialData : {
            entity_type: null,
            contact_type: 'other',
            lead_pipeline_state: null,
            email_ending: null,
            position: '',
            avatar: null,
            parent: null,
            salutation: '',
            title: '',
            first_name: '',
            last_name: '',
            entity_name: '',
            phone: '',
            mobile: '',
            email: null,
            favored_contact_method: '',
            email_allowed: false,
            topics: [],
            addresses: [],
            contact_origin: null,
            tags: [],
            responsible_users: [],
        },
        validationSchema: Yup.object().shape({
            entity_type: Yup.string()
                .oneOf(ENTITY_TYPES, t('form.invalid_selection', {field: t('attributes.entity_type')}))
                .required(t('form.is_required', {field: t('attributes.entity_type')})),
            contact_type: Yup.string()
                .oneOf(CONTACT_TYPES, t('form.invalid_selection', {field: t('attributes.type')}))
                .required(t('form.is_required', {field: t('attributes.type')})),
            lead_pipeline_state: Yup.string()
                .oneOf(LEAD_PIPELINE_STATES.concat([null]), t('form.invalid_selection', {field: t('attributes.lead_state')}))
                .nullable(),
            parent: Yup.number()
                .nullable(),
            position: Yup.string()
                .max(200, t('form.max_length', {characters: 200})),
            first_name: Yup.string()
                .max(200, t('form.max_length', {characters: 200})),
            last_name: Yup.string()
                .max(200, t('form.max_length', {characters: 200})),
            entity_name: Yup.string()
                .max(200, t('form.max_length', {characters: 200})),
            phone: Yup.string()
                .max(40, t('form.max_length', {characters: 40}))
                .nullable(),
            mobile: Yup.string()
                .max(40, t('form.max_length', {characters: 40}))
                .nullable(),
            email_ending: Yup.string().nullable(),
            email: Yup.string()
                .email(t('form.invalid_email'))
                .nullable()
                .max(200, t('form.max_length', {characters: 200})),
            website: Yup.string()
                .url(t('form.invalid_url'))
                .max(200, t('form.max_length', {characters: 200}))
                .nullable(),
            email_allowed: Yup.bool(),
            favored_contact_method: Yup.string()
                .max(200, t('form.max_length', {characters: 200}))
        }),
        onSubmit: onSubmit,
    })

    const contactAddressFormik = useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            street: '',
            number: '',
            addition: '',
            city: '',
            postal: '',
            country: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            name: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.name')})),
            street: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.street')})),
            number: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.number')})),
            addition: Yup.string().max(200, t('form.max_length', {characters: 200})),
            city: Yup.string().max(200, t('form.max_length', {characters: 200})),
            postal: Yup.string().max(200, t('form.max_length', {characters: 200})),
            country: Yup.string().max(200, t('form.max_length', {characters: 200})),
        }),
        onSubmit: onSubmit,
    })

    const contactEmailFormik = useFormik({
        initialValues: initialData ? initialData : {
            subject: '',
            body: '',
            sender_name: null,
            reply_to: null,
            context: null,
            contacts: [],
            planned_at: null,
            bbc_sender: false,
            images: [],
            ctas: [],
            attachments: []
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            subject: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.subject')})),
            reply_to: Yup.string().max(500, t('form.max_length', {characters: 500})).nullable(),
            body: Yup.string(),
            bbc_sender: Yup.bool(),
            context: Yup.number(),
            sender_name: Yup.string().nullable(),
            contacts: Yup.array(),
            planned_at: Yup.date().nullable(),
            images: Yup.array(),
            ctas: Yup.array(),
            attachments: Yup.array()
        }),
        onSubmit: onSubmit,
    })

    const contactOriginFormik = useFormik({
        initialValues: initialData ? initialData : {
            name: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            name: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.name')})),
        }),
        onSubmit: onSubmit,
    });

    const contactBankAccountFormik = useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            bank_name: '',
            iban: '',
            bic: '',
            account_holder: '',
            contact: null,
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            name: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.name')})),
            bank_name: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.bank_name')})),
            iban: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.iban')})),
            bic: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.bic')})),
            account_holder: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.account_holder')})),
            contact: Yup.number().required(t('form.is_required', {field: t('attributes.contact')})),
        }),
        onSubmit: onSubmit,
    })

    const contactNoteFormik = useFormik({
        initialValues: initialData ? initialData : {
            title: '',
            type: '',
            body: '',
            time: '',
            contacts: [],
            users: [],
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.title')})),
            type: Yup.string()
                .max(20, t('form.max_length', {characters: 20})).required(t('form.is_required', {field: t('attributes.type')}))
                .oneOf(NOTE_TYPES, t('form.invalid_selection', {field: t('attributes.type')})),
            body: Yup.string(),
            time: Yup.date().nullable().required(t('form.is_required', {field: t('attributes.time')})),
            contacts: Yup.array(),
            users: Yup.array(),
        }),
        onSubmit: onSubmit,
    })

    const onlineAccountFormik = useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            url: '',
            account_type: 'other',
            contact: null,
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            name: Yup.string().max(200, t('form.max_length', {characters: 200})),
            url: Yup.string()
                .max(200, t('form.max_length', {characters: 200}))
                .url(t('form.invalid_url'))
                .required(t('form.is_required', {field: t('attributes.url')})),
            account_type: Yup.string()
                .max(200, t('form.max_length', {characters: 200})).required(t('form.is_required', {field: t('attributes.account_type')}))
                .oneOf(ACCOUNT_TYPES, t('form.invalid_selection', {field: t('attributes.account_type')})),
            contact: Yup.number().required(t('form.is_required', {field: t('attributes.contact')})),
        }),
        onSubmit: onSubmit,
    })

    return { contactFormik, ENTITY_TYPES, CONTACT_TYPES, LEAD_PIPELINE_STATES, contactEmailFormik,
        contactBankAccountFormik, contactAddressFormik, contactOriginFormik, contactNoteFormik, NOTE_TYPES,
        contactTaskFormik, TASK_TYPES, TASK_PRIORITIES, TASK_STATUS, onlineAccountFormik, ACCOUNT_TYPES,
        contextFormik, CONTEXT_KINDS, CONTEXT_RESPONSIBILITY_ASSIGNMENT, CONTEXT_LAYOUTS, CONTEXT_ACCESS,
        RESPONSIBILITY_ASSIGNMENT_HELPERS, CONTEXT_REQUIRED_FIELDS, CONTEXT_PALETTE_MODES,
        CONTEXT_NAV_COLORS, contextNotificationFormik, eventIcons }
}

export default useCrmFormik;
